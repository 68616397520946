import React, { useEffect, useRef } from "react";
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Banner = () => {
  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);
  return (
    <>
      <section className="home-banner-section">
        <div className="black-overlay-vdo">
        </div>

        <div className="banner-slider-div">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <video src={process.env.PUBLIC_URL + "assets/Videos/home/riawanvideo.mp4"} ref={vidRef}  muted
                loop
                className=" video-class-grid"
              />
              

              <div className="blue-background-relative">
                <div className="blue-background">
                  <div className="content-main">
                    <h1 className="content">
                      Connect Faster <br></br>and Easier with
                    </h1>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/banner/heading.png"
                      }
                      className="img-fluid mt-2"
                      alt="..."
                    />
                    <div className="check-btn">
                      <button type="button" class="btn btn-primary"> Check Plans </button>
                    </div>
                  </div>

                  <div className="overlay-black-bg">

                  </div>
                </div>
              </div>
            </SwiperSlide>


          </Swiper>
        </div>




        <div className="overlay-cards">
          <div className="cards-main-section">
            <div className=" container-fluid container-md">
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4 col-12 mb-4">
                  <div className="images-main-section">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/banner/image1.png"
                      }
                      className="card-image"
                      alt="..."
                    />
                    <div className="text">
                      <h6 className="home">
                        Home Broadband<br></br> Internet
                      </h6>
                      <div className="d-flex">
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/Icon ionic-ios-rocket.png"
                            }
                            className="rocket me-3"
                            alt="..."
                          />
                        </div>
                        <div className="text-card">
                          <h6 className="speed mt-1">High Speed Internet</h6>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/Icon ionic-ios-call.png"
                            }
                            className="rocket me-3"
                            alt="..."
                          />
                        </div>
                        <div className="text-card">
                          <h6 className="support mt-1">24/7 Support</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4 col-12 mb-4">
                  <div className="images-main-section">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/banner/image3.png"
                      }
                      className="card-image"
                      alt="..."
                    />
                    <div className="text">
                      <h6 className="home">
                        Corporate / SME <br></br>Internet
                      </h6>
                      <div className="d-flex">
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/Icon ionic-ios-rocket.png"
                            }
                            className="rocket me-3"
                            alt="..."
                          />
                        </div>
                        <div className="text-card">
                          <h6 className="speed mt-1">High Speed Internet</h6>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/Icon ionic-ios-call.png"
                            }
                            className="rocket me-3"
                            alt="..."
                          />
                        </div>
                        <div className="text-card">
                          <h6 className="support mt-1">24/7 Support</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4 col-12 mb-4">
                  <div className="images-main-section">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/banner/image2.png"
                      }
                      className="card-image "
                      alt="..."
                    />
                    <div className="text">
                      <h6 className="home">
                        Dedicated <br></br>Servers
                      </h6>
                      <div className="d-flex">
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/Icon ionic-ios-rocket.png"
                            }
                            className="rocket me-3"
                            alt="..."
                          />
                        </div>
                        <div className="text-card">
                          <h6 className="speed mt-1">High Speed Internet</h6>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="icon">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Images/banner/Icon ionic-ios-call.png"
                            }
                            className="rocket me-3"
                            alt="..."
                          />
                        </div>
                        <div className="text-card">
                          <h6 className="support mt-1">24/7 Support</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  );
};

export default Banner;