import React from "react";
import "../Header/Header.css";
import { Link } from "react-router-dom"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OnlineRegistration from "../OnlineRegistration/OnlineRegistartion";
library.add(fas);
const Header = () => {
  return (
    <>
      <section className="main sticky-top">
        {/* top header section start */}
        <div className="top-header">
          <div className="  container">
            <div className="row ">
              <div className=" col-xl-6 col-md-6 col-6">
                <div className="text-holder">
                  <div className="row">
                    <div className="col-xl-5 col-md-6 col-12 contact-no">
                      <FontAwesomeIcon
                        icon="fa-solid fa-phone"
                        className="me-1"
                      />
                      <span>000 - 2222222 / 3333333</span>
                    </div>

                    <div className="col-xl-5 col-md-6 col-12 email-info">
                      <FontAwesomeIcon
                        icon="fa-solid fa-envelope"
                        className="me-1"
                      />
                      <span>rizwanwifi@qmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-xl-6 col-md-6 col-6">
                <div className="text-holder">
                  <div className="row text-end">
                    <div className="col-xl-7 col-md-4 col-12 lCO-link">
                      <a>
                        <span>Find Your LCO</span>
                      </a>
                    </div>

                    <div className="col-xl-3 col-md-5 col-12 header-link">
                      <a>
                        <span>New Connection</span>
                      </a>
                    </div>

                    <div className="col-xl-2 col-md-3 col-12 recharge-link">
                      <a>
                        <span>Recharge</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* top header section end */}

        {/* main header section  start */}
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container">
            <Link to="/" className="navbar-brand">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "assets/Images/header/rizwan_wifi.png"
                }
                className=" header-logo img-fluid"
                alt="..."
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ">
                <li className="nav-item">
                  <Link to="/" className="nav-link active" aria-current="page">
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Broadband
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to='/book' className="dropdown-item">Book New Connection</Link>
                    </li>
                    <li>
                      <Link to='/plan' className="dropdown-item">Plans</Link>
                    </li>
                    <li>
                      <a className="dropdown-item">Something else here</a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Consumer Corner
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to='/registration' className="dropdown-item">Online Registartion</Link>
                    </li>
                    <li>
                      <Link to='/subscribe' className="dropdown-item">Subscriber Corner</Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Complaints
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to='/filecomplaint' className="dropdown-item">File a Complaint</Link>
                    </li>
                    <li>
                      <Link to='/complaint' className="dropdown-item">Track Complaint</Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Help & Support
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link to='/help' className="dropdown-item">Helpdesk</Link>
                    </li>
                    <li>
                      <Link to='/upgrade' className="dropdown-item">Upgrade to HD</Link>                      
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* main header section  end */}
      </section>
    </>
  );
};
export default Header;
