import React from "react";
import GetInTouch from "./GetInTouch/GetInTouch";
import Servicess from "./Servicess/Servicess";
import Banner from "./banner/Banner";
import Howfast from "./how_fast/Howfast";
import WhoWEAre from "./who-we-are/WhoWEAre";
import RizwanDigital from "./rizwan_digital/RizwanDigital";
const HomePage = () => {
   
    return (
      <>
      <Banner />
      <WhoWEAre/>
      <RizwanDigital/>
      <Howfast />
      <Servicess/>
      <GetInTouch/>
      </>
    );
};
export default HomePage;