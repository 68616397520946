import React from "react";
import HomeBanner from "./Homebanner/HomeBanner";
import Plantype from "./PlanType/PlanType.js";
import TermsCondition from '../Plans/TermsCondition/TermsCondition.js'
import WhyUcn from "../Plans/WhyUcn/WhyUcn.js";





const HomePlan = () => {   
    return (
      <>
        <HomeBanner/>
        <Plantype/>
        <TermsCondition/>
        <WhyUcn/>
      </>
    );
};
export default HomePlan;