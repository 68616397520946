import React, { useEffect, useRef } from "react";
import "./Howfast.css";

const Howfast = () => {

    const vidRef = useRef();

    useEffect(() => {
      vidRef.current.play();
    }, []);
  return (
    <>
        <div className='container-fluid p-0'>
            <section className='how-fast-section'>
                <div className='video-sec'>
                    <video src={process.env.PUBLIC_URL + "assets/Videos/home/how-we.mp4"} ref={vidRef} muted
                    loop className=" video" />
                </div>
                <div className="blackoverlay"> 
                </div>

                <div className="container">   
                    <div className="content-overflow-1">
                        <div className="row ms-0 me-0">
                            <div className="col-md-4 col-sm-12 ">
                                <div className="sec">
                                    <h1 className="title-set">How Fast Can I Download?</h1>
                                    <p className="content">Take a look at how long it might take
                                        to download music or movies, based
                                        on different broadband speeds. With
                                        on demand you’ll be able to start
                                        watching shortly after the download
                                        has started, no need to wait for the
                                        full download.</p>
                                </div>
                            </div>

                            <div className="col-md-2 col-sm-6 col-6">
                                <div className="sec-2">
                                    <div className="d-flex mb-5">
                                        <div className="icon-div">
                                            <img className="icon" src={process.env.PUBLIC_URL + "assets/Images/home/how-fast/wifi.png"} />
                                        </div>
                                        <div className="content-div-1">
                                            <div className="d-flex">
                                                <div className="get ms-2">
                                                    <p className="get-p">Get</p>
                                                  
                                                    <p className="get-p">upto</p>
                                                </div>
                                                
                                                <div className="get">
                                                    <p className="spped">1</p>
                                                </div>

                                                <div className="get">
                                                    <p className="gbps">GBPS</p>
                                                </div>

                                            </div>

                                            <p className="ultra">Ultra Speed</p>
                                        </div>
                                    </div>


                                    <div className="d-flex">
                                        <div className="icon-div">
                                            <img className="icon" src={process.env.PUBLIC_URL + "assets/Images/home/how-fast/download.png"} />
                                        </div>
                                        <div className="content-div-1 ms-2">
                                            <div className="d-flex">
                                                
                                                <div className="get">
                                                    <p className="spped">1000</p>
                                                </div>

                                                <div className="get">
                                                    <p className="gbps">GB</p>
                                                </div>

                                            </div>

                                            <p className="ultra">Downloads</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-5 col-sm-6 col-6">
                                <div className="sec-3">
                                    <img className="rout-img" src={process.env.PUBLIC_URL + "assets/Images/home/how-fast/router.png"} />
                                </div>
                            </div>
                        </div>
                    </div>                       
                </div>
            </section>

            <section className="counter-section">
                <div className="conter-overlay">                   
                    <div className="container">
                        <div className="counter-box">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                                    <div className="box">
                                        <h1 className="number">28K+</h1>
                                        <p className="memmm">Active Members</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                                    <div className="box">
                                        <h1 className="number">20K+</h1>
                                        <p className="memmm">Happy Clients</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                                    <div className="box">
                                        <h1 className="number">150+</h1>
                                        <p className="memmm">Professional Team</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-center">
                                    <div className="box">
                                        <h1 className="number">10+</h1>
                                        <p className="memmm">Years Experience</p>
                                    </div>
                                </div>
                                
                            </div>  
                        </div>
                    </div>                    
                </div>
            </section>

            
        </div>
    </>
  )
}

export default Howfast;