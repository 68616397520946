import React from "react";
import "../Footer/Footer.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);
const Footer = () => {
  return (
    <>
      <section className="footer ">
        <div className="container">
          <div className="row footer_row_main  justify-content-center pt-4 pb-2">
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 links">
              <a className="navbar-brand" >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "assets/Images/footer/footer_logo.png"
                  }
                  className=" footer-logo img-fluid"
                  alt="..."
                />
              </a>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 links">
              <div className="text-holder">
                <h6>Quick Links</h6>
                <div className="hr-lines-1"></div>
              </div>
              <div className="row">
                <div className="col-xl-5 col-md-6 col-12">
                  <div className="text-holder">
                    <a ><p>About Us</p></a>
                    <a ><p>Work with Us</p></a>
                    <a ><p>Careers</p></a>
                    <a ><p>Contact Us</p></a>
                    <a ><p>Help & Support</p></a>
                  </div>
                </div>
                <div className="col-xl-7 col-md-6 col-12">
                  <div className="text-holder">
                  <a ><p>Compliances</p></a>
                  <a ><p>Privacy Policy</p></a>
                  <a ><p>Advertise with Us</p></a>
                  <a ><p>Manual of Practice</p></a>
                  <a ><p>Terms & Conditions</p></a>
                  <a ><p>Internet Application Form</p></a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-xl-3 col-lg-3 col-md-3 col-sm-6 links">
              <div className="text-holder">
                <h6>Head Office</h6>
                <div className="hr-lines-2"></div>
              </div>
              <div className="text-holder">
              <div className="text d-flex">
              <FontAwesomeIcon icon="fa-solid fa-location-dot" className="icon me-1"/><p>502, Milestone, 12, Ramdaspeth, Nagpur-440010</p>
              </div>             
              <div className="text d-flex">
              <FontAwesomeIcon icon="fa-solid fa-phone" className="icon me-1"/> <p>000 - 2222222 / 3333333</p>
              </div>
              <div className="text d-flex">
              <FontAwesomeIcon icon="fa-solid fa-envelope" className="icon me-1"/><p>rizwanwifi@qmail.com</p>
              </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-2 col-sm-6 links">
            <div className="text-holder">
                <h6>Digital TV & Broadband</h6>
                <div className="hr-lines-3"></div>
              </div>
            
              <div className="text-holder">
              <div className="text d-flex">
              <FontAwesomeIcon icon="fa-solid fa-phone" className="icon me-1"/> <p>9876543210</p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bottom-footer">
        <div className="text-holder text-center">
          <span>
            © 2021 All Rights Reserved Terms Of Use | Privacy Policy Powered by
            Profcyma
          </span>
        </div>
      </section>
    </>
  );
};
export default Footer;
