import React from "react";
import BookBanner from './BookBanner/BookBanner.js';
// import BookForm from './BookForm/BookForm.js'
import Registration from '../BookNewConnection/Registration/Registration.js'

const Plans = () => {   
    return (
      <>
      {/* <BookBanner/> */}
      <Registration/>

      </>
    );
};
export default Plans;