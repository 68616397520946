import React from "react";
import '../TrackComplaintForm/TrackComplaintForm.css'



const TrackComplaintForm = () => {
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <div className="registration-form">
            <div className="container">
              <div className="text-container">
                <div className="row text-start p-4">
                  <div className="col-xl-11 col-md-11 col-11">
                    <p>
                      <a href="" className=" home-link">
                      Home
                      </a>
                      <span> &gt; </span> <span>Complaint Management</span>
                      <span> &gt; </span> <span> Track Complaints</span>                      
                    </p>
                  </div>
                </div>
                <div className="heading p-4 ">
                  <h3> Track a Complaint</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="complete-form">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="border-one">
                  <div></div>
                </div>
                <div className="main-form">
                  <div className="form-section">
                    <form className="overlay-form p-4 ">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="phoneInput"
                                placeholder="Enter Your Complaint Number"
                              />
                            </div>
                            <div className="custom-button  ">
                              <button type="submit" className="btn">
                              Check
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <img src={process.env.PUBLIC_URL + "assets/Images/Subscribe_Corner/girl_user.png"} className="user-img" />
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};
export default TrackComplaintForm;
