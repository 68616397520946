import React from "react";
import TrackComplaintForm from "./TrackComplaintForm/TrackComplaintForm.js";


const TrackComplaint = () => {   
    return (
      <>
      <TrackComplaintForm/>

      </>
    );
};
export default TrackComplaint;