import React from "react";
import "../PlanType/PlanType.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Plantype = () => {

    return (
        <>
            <div className="main">
                {/* plans package banner section start */}
                <section className="Plan-Type">
                    <div className="container">
                        <div className="row mt-5 mb-5">
                            <div className="col-md-12">
                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Home Plan <img src={process.env.PUBLIC_URL + "assets/Images/BookNewConnection/Banner/arrow1.png"} className=" arrow-img img-fluid ms-3" alt="..." /></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Buisness Plan <img src={process.env.PUBLIC_URL + "assets/Images/BookNewConnection/Banner/arrow1.png"} className=" arrow-img img-fluid ms-3" alt="..." /> </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-3 col-12">
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                        <div class="second-tab d-flex align-items-start">
                                            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Unlimited-Plans</button>
                                                <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Long Validity Plan</button>
                                                <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Budget Plan</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                        <div class="second-tab d-flex align-items-start">
                                            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Unlimited-Plans</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-9 col-md-9">

                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="internet-speed">
                                            <div className="card text-center">
                                                <p >Speed up to <br /><span> 75 Mbps</span></p>
                                            </div>
                                            <div className="text-holder-speed text-center">
                                                <p className="mt-3 ">Up / Down Stream <br /> <span>75 Mbps</span></p>
                                                <div className="underline-class ms-4 me-4"></div>
                                                <p className="mt-3 pb-5">Monthly Charges <br /><span>₹ 850 + GST</span></p>
                                            </div>
                                        </div>
                                        <div className="select-btn-main1 text-center">
                                            <button type="button" class="btn select-button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                Select
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="internet-speed">
                                            <div className="card text-center">
                                                <p >Speed up to <br /><span> 75 Mbps</span></p>
                                            </div>
                                            <div className="text-holder-speed text-center">
                                                <p className="mt-3 ">Up / Down Stream <br /> <span>75 Mbps</span></p>
                                                <div className="underline-class ms-4 me-4"></div>
                                                <p className="mt-3 pb-5">Monthly Charges <br /><span>₹ 850 + GST</span></p>
                                            </div>
                                        </div>
                                        <div className="select-btn-main2 text-center">
                                            <button type="button" class="btn select-button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                Select
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <div className="internet-speed">
                                            <div className="card text-center">
                                                <p >Speed up to <br /><span> 75 Mbps</span></p>
                                            </div>
                                            <div className="text-holder-speed text-center">
                                                <p className="mt-3 ">Up / Down Stream <br /> <span>75 Mbps</span></p>
                                                <div className="underline-class ms-4 me-4"></div>
                                                <p className="mt-3 pb-5">Monthly Charges <br /><span>₹ 850 + GST</span></p>
                                            </div>
                                        </div>
                                        <div className="select-btn-main3 text-center">
                                            <button type="button" class="btn select-button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                Select
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* MOdal */}
                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="staticBackdropLabel">Limited 75 mbps GOLD 1</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                {/* <label for="exampleInputEmail1" className="form-label">First Name</label> */}
                                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" required />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {/* <label for="exampleInputPassword1" className="form-label"></label> */}
                                                <input type="email" className="form-control" id="exampleInputPassword1" placeholder="Email ID" required />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {/* <label for="exampleInputEmail1" className="form-label" >Mobile No</label> */}
                                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description" />
                                            </div>
                                            <div className="col-md-12 mb-3">
                                                {/* <label for="exampleInputEmail1" className="form-label" >Email Id</label> */}
                                                <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile No" />
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer text-start">
                                    <div></div>
                                    <button type="button" className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* plans package banner section end */}
            </div>
        </>
    );
};
export default Plantype;
