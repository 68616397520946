import React from "react";
import "../WhyUcn/WhyUcn.css";
const WhyUcn = () => {
  
  return (
    <>
        {/* Why UCN Broadband ? section start */}
        <section className="Why-UCN-Broadband">
          <div className="container">
            <div className="text-holder">
              <h1>Why UCN Broadband ?</h1>
              <div className="underline"></div>
            </div>
            <div className="card-section">
              <div className="row">
                <div class=" col-xl-3 col-md-4 col-sm-6 col-12 mb-3 p-0 ">
                  <div class="card">
                  <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Broadband/PlansPackage/internet speed.png"
                  }
                  className="card-img "
                  alt="..."
                />
                    <div class="card-body">
                      <h5 class="card-title">Faster In-Home & Business Internet</h5>
                      <p class="card-text">
                      Surf, stream and share on more devices with our faster in - house Home Wi-Fi.
                      </p>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div class=" col-xl-3 col-md-4 col-sm-6 col-12 mb-3  p-0">
                  <div class="card">
                    <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Broadband/PlansPackage/plans.png"
                  }
                  className="card-img "
                  alt="..."
                />
                    <div class="card-body">
                      <h5 class="card-title">Wide range of Plans</h5>
                      <p class="card-text">
                      Get the Best plan suiting your requirements. Top up plans just in case you run out of Data.
                      </p>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div class=" col-xl-3 col-md-4 col-sm-6 col-12 mb-3 p-0 ">
                  <div class="card">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Images/Broadband/PlansPackage/dependable.png"
                      }
                      className="card-img "
                      alt="..."
                    />
                    <div class="card-body">
                      <h5 class="card-title">Get more Dependability</h5>
                      <p class="card-text">
                      Stream videos and Music smoothly without any Cuts or Buffers.
                      </p>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
                <div class=" col-xl-3 col-md-4 col-sm-6 col-12 mb-3 p-0">
                  <div class="card">
                  <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/Broadband/PlansPackage/help-desk.png"
                  }
                  className="card-img "
                  alt="..."
                />
                    <div class="card-body ">
                      <h5 class="card-title">Superior Service and Support</h5>
                      <p class="card-text">
                      Reliable & Fast. Service you can trust 100%.
                      </p>
                    </div>
                    <div className="underline"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Why UCN Broadband ? section end */}
    </>
  );
};
export default WhyUcn;
