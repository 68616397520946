import React from "react";
import OnRegistration from "./OnRegistration/OnRegistration.js";

const OnlineRegistration = () => {   
    return (
      <>
      <OnRegistration/>

      </>
    );
};
export default OnlineRegistration;