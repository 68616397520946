import React from "react";
import "../TermsCondition/TermsCondition.css";
const PlansPackage = () => {
  
  return (
    <>
      <div className="main">
        {/* terms and conditions section start */}
        <section className="terms-and-conditions">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-md-12 col-12">
                <div className="text-holder">
                  <h1>Terms and conditions</h1>
                  <ul>
                    <li>
                      <span>
                        Rizwan WiFi reserves the right to modify/withdraw the
                        tariff plans without prior notice.
                      </span>
                    </li>
                    <li>
                      <span>
                        Activation and installation charges are non-refundable.
                      </span>
                    </li>
                    <li>
                      <span>
                        The tariff plans / offers are subject to Guidelines/
                        Directives / Orders issued by TRAI and / or DOT.{" "}
                      </span>
                    </li>
                    <li>
                      <span>
                        Download speed indicates only up to ISP node.{" "}
                      </span>
                    </li>
                    <li>
                      <span>Installation Charges as per Actuals.</span>
                    </li>
                    <li>
                      <span>
                        Documents Required: Photo ID proof, Address Proof,
                        passport size photo.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* terms and conditions section end */}
      </div>
    </>
  );
};
export default PlansPackage;
