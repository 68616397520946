import React from "react";
import FileComplaintForm from "./FileComplaintForm/FileComplaintForm.js";


const FileComplaint = () => {   
    return (
      <>
      <FileComplaintForm/>

      </>
    );
};
export default FileComplaint;