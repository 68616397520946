import React, { useEffect, useRef } from "react";
import "./whoweare.css";

const WhoWEAre = () => {
    const vidRef = useRef();

    useEffect(() => {
      vidRef.current.play();
    }, []);
    return (
        <>
            <div className='container-fluid p-0'>
                <section className='who_we_are'>
                    <div className="row justify-content-center me-0 ms-0">
                        <div className="who_we_are">
                            <div className="container">
                                <div className="row who-main">
                                    <div className="col-md-7">
                                        <div className="d-flex">
                                            <div className="wifi">
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/Images/banner/wifi.png"
                                                    }
                                                    className="img-fluid mt-2 me-3"
                                                    alt="..."
                                                />
                                            </div>
                                            <div className="who-heading">
                                                <h4 className="who_we mt-2">Who We Are</h4>
                                            </div>
                                        </div>
                                        <h1 className="experience mt-3">Experience The Magic of Technology</h1>
                                        <p className="lorem mt-lg-5 mt-md-3 mt-1">Lorem Ipsum is simply dummy text of the printing and typesetting <br></br>industry. Lorem Ipsum has been the industry's standard dummy text<br></br> ever since the 1500s, when an unknown printer took a galley of type <br></br>and scrambled it to make a type specimen book. It has survived not <br></br>only five centuries, but also the leap into electronic typesetting,<br></br> remaining essentially unchanged.</p>
                                    </div>
                                    <div className="col-md-5 mt-4">
                                        <video
                                            src={process.env.PUBLIC_URL + "assets/Videos/home/square.mp4"}
                                            ref={vidRef}
                                            muted
                                            loop
                                            className="who_video"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default WhoWEAre;