import React from "react";
import "../GetInTouch/GetInTouch.css";
const GetInTouch = () => {
  return (
    <>
      <section className="get_in_touch   ">

        <div className="container-fluid p-0">
          <div className=" ">

            <div className="overlay-content">
              <div className="container">
                <div className="row ">
                  <div className="col-md-6 ">
                    <div className="get_in_touch_content ">
                      <div className="overlay-text ">
                        {/* <div className="icon-text p-2"> */}
                        {/* <div className="d-flex  align-items-center  "> */}
                        <div className=" icon-text ">

                          <div className="wifi-icon  ">
                            <img  src={
                                process.env.PUBLIC_URL + "assets/Images/GetInTouch/wifi-icon.png" }
                              className=" get-bg-img img-fluid "
                              alt="Background"
                            />
                            <div>
                              <h3 className="text-light heading py-0 ">Get in touch</h3>
                            </div>
                          </div>


                        </div>
                        {/* </div> */}
                        {/* </div> */}
                        <div className="p-1">
                          <p className="text-light descriptn ">
                            Drop a Message <br></br> We will be there for You!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 ">

                    <div className="hor-bd-div">
                      <div className="d-flex ">
                        <div className="bd1"></div>
                        <div className="bd2"></div>
                      </div>
                    </div>


                    <div className="form-section">

                      <form className="overlay-form  ">
                        <div className="mb-3  ">
                          <label htmlFor="nameInput" className="form-label"></label>
                          <input
                            type="text"
                            className="form-control"
                            id="nameInput"
                            placeholder=" Name:"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="emailInput" className="form-label"></label>
                          <input
                            type="email"
                            className="form-control"
                            id="emailInput"
                            placeholder="E-mail:"
                          />
                        </div>
                        <div>
                          <div className="mb-3">
                            <label
                              htmlFor="phoneInput"
                              className="form-label"
                            ></label>
                            <input
                              type="tel"
                              className="form-control"
                              id="phoneInput"
                              placeholder=" phone:"
                            />
                          </div>

                          <div className="mb-3">
                            <label htmlFor="description"></label>
                            <textarea
                              className="form-control"
                              id="description"
                              rows={4}
                              placeholder="Type your requirement here.."
                            />
                          </div>
                          <div className="custom-button">
                            <button type="submit" className="btn   ">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </section>
    </>
  );
};
export default GetInTouch;
