import React from "react";

const OnRegistration = () => {
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <div className="registration-form">   
            <div className="container">
              <div className="text-container">
                <div className="row text-start p-4">
                  <div className="col-xl-11 col-md-11 col-11">
                    <p>
                      <a href="" className=" home-link">
                        Home
                      </a>
                      <span> &gt; </span> <span> User  Registration</span>
                    </p>
                  </div>
                </div>
                <div className="heading p-4 ">
                <h3> Online Registration </h3>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="complete-form">
            <div className="col-md-6 mx-auto">              
              <div className="border-one">
                <div></div>
              </div>
              <div className="main-form">
                <div className="form-section">
                  <form className="overlay-form  ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3  ">
                          <label htmlFor="nameInput" className="form-label">
                            First name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="nameInput"
                            placeholder="Enter First Name"
                          />
                        </div>
                        <div>
                          <div className="mb-3">
                            <label htmlFor="phoneInput" className="form-label">
                              Mobile No <span className="text-danger">*</span>
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              id="phoneInput"
                              placeholder=" Enter Mobile No"
                            />
                          </div>

                          <div className="mb-3  ">
                            <label htmlFor="username" className="form-label">
                              Username <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="sername"
                              placeholder=" Enter User Name:"
                            />
                          </div>

                          <div className="mb-3  ">
                            <label htmlFor="nameInput" className="form-label">
                              Security Question{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="nameInput"
                              placeholder=" Select"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3  ">
                          <label htmlFor="nameInput" className="form-label">
                            Last name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="nameInput"
                            placeholder="Enter Last name"
                          />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="emailInput" className="form-label">
                            Email-Id <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="emailInput"
                            placeholder=" Enter E-mail"
                          />
                        </div>
                        <div className="mb-3  ">
                          <label htmlFor="password" className="form-label">
                            password <span className="text-danger">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="pass"
                            placeholder=" Enter Password"
                          />
                        </div>
                        <div className="mb-3  ">
                          <label htmlFor="nameInput" className="form-label">
                            Security Answer{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="nameInput"
                            placeholder="Enter Security Answer "
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="custom-button  ">
                  <button type="submit" className="btn">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default OnRegistration;
