import React from "react";
import "../Registration/Registration.css";
import { Link } from "react-router-dom";

const Registration = () => {
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <div className="registration-form">   
            <div className="container">
              <div className="text-container">
                <div className="row text-start">
                  <div className="col-xl-11 col-md-11 col-11">
                    <p>
                      <a href="" className=" home-link">
                        Home
                      </a>
                      <span> &gt; </span> <span> User  Registration</span>
                    </p>
                  </div>
                </div>
                <div className="heading mt-5">
                <h3> Online Registration </h3>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="complete-form">
            <div className="col-md-10 mx-auto">
              <div className="border-one">
                <div></div>
              </div>
              <div className="main-form">
                <div className="form-section">
                  <form className="overlay-form  ">                  
                      <div className="Book-Form ">
                        <div className="container">
                          <div className="book-main">
                            <form>
                              <div className="row">
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputEmail1" className="form-label">First Name<span className='star-img'>*</span></label>
                                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name" required />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputPassword1" className="form-label">Last Name<span className='star-img'>*</span></label>
                                  <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Enter Last Name" required />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputEmail1" className="form-label" >Mobile No<span className='star-img'>*</span></label>
                                  <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name" />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputEmail1" className="form-label" >Email Id<span className='star-img'>*</span></label>
                                  <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" />
                                </div>
                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputPassword1" className="form-label">Address<span className='star-img'>*</span></label>
                                  <input type="text" className="form-control address" id="exampleaddress1" placeholder="Address" />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputEmail1" className="form-label" >Pin Code<span className='star-img'>*</span></label>
                                  <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Pin Code" />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputPassword1" className="form-label">State</label>
                                  <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter State" />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputEmail1" className="form-label" >District</label>
                                  <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter District" />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <label for="exampleInputPassword1" className="form-label">Taluka</label>
                                  <input type="text" className="form-control" id="exampleInputPassword1" placeholder="Enter Taluka" required />
                                </div>
                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-3">
                                  <form className="city-form" action="">
                                    <label className='form-label mb-2'>Select Area</label>
                                    <select class="form-select form-control" aria-label="Default select example">
                                      <option selected>Select Area</option>
                                      <option value="1">Pune</option>
                                      <option value="2">Mumbai </option>
                                      <option value="3">Kolhapur</option>
                                    </select>
                                  </form>
                                </div>
                              </div>
                            </form>
                          </div>

                        </div>
                      </div>                  
                  </form>
                </div>
                <div className="col-md-12 text-center mt-5 mb-5">
                  <button type="submit" className="btn next-btn">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Registration;
