import React from 'react'
import "./rizwan-digital.css";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const RizwanDigital = () => {
    return (
        <>
            <div className='container-fluid'>
                {/* Router section started*/}
                <section className='rizwan-hd-section'>
                    <div className='container'>
                        <div className="d-flex digital">
                            <div className="wifi1">
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Images/banner/wifi.png"
                                    }
                                    className="img-fluid mt-2 me-3"
                                    alt="..."
                                />
                            </div>
                            <div className="who-heading">
                                <h1 className="rizwan mt-2">Rizwan Digital HD & Internet</h1>
                            </div>
                        </div>
                    </div>

                    <div className='router-banner-div'>
                        <img className='banner' src={process.env.PUBLIC_URL + "assets/Images/banner/router.png"} />
                    </div>
                </section>





                {/* Router slider started */}


                <section className='router-slider-section'>
                    <div className="router-slider">
                        <Swiper
                            spaceBetween={30}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}

                            breakpoints={{
                                '0': {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                '576': {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                '768': {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                '992': {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                '1200': {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                                '1400': {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                }
                            }}

                            navigation={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <div className="router-cards">
                                    <div className="main-images ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "./assets/Images/banner/HD-07.png"
                                            }
                                            className="hd mt-3 me-3"
                                            alt="..."
                                        />
                                    </div>
                                    <h6 className="recording text-center mt-2">HD Recording</h6>
                                    <p className="quality text-center mt-2">High picture quality, Stereophonic Crispy Sound.</p>

                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="router-cards">
                                    <div className="main-images ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "./assets/Images/banner/recording-08.png"
                                            }
                                            className="hd mt-3 me-3"
                                            alt="..."
                                        />
                                    </div>
                                    <h6 className="recording text-center mt-2">Unlimited Recording</h6>
                                    <p className="quality text-center mt-2">Lorem Ipsum</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="router-cards">
                                    <div className="main-images ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "./assets/Images/banner/time.png"
                                            }
                                            className="time mt-3 me-3"
                                            alt="..."
                                        />
                                    </div>
                                    <h6 className="recording text-center mt-2">Viewing Aspect Ratio</h6>
                                    <p className="quality text-center mt-2">True HD-1080i Display.</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="router-cards">
                                    <div className="main-images ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "./assets/Images/banner/layer1.png"
                                            }
                                            className="digital mt-3 me-3"
                                            alt="..."
                                        />
                                    </div>
                                    <h6 className="recording text-center mt-2">Dolby Digital Plus Surround Sound</h6>
                                    <p className="quality text-center mt-2">Dolby Digital Plus Surround Sound ‘E.AC-3’</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="router-cards">
                                    <div className="main-images ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "./assets/Images/banner/HD-07.png"
                                            }
                                            className="hd mt-3 me-3"
                                            alt="..."
                                        />
                                    </div>
                                    <h6 className="recording text-center mt-2">Series Recording</h6>
                                    <p className="quality text-center mt-2">Live Play and Pause Feature.</p>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="router-cards">
                                    <div className="main-images ">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "./assets/Images/banner/HD-07.png"
                                            }
                                            className="hd mt-3 me-3"
                                            alt="..."
                                        />
                                    </div>
                                    <h6 className="recording text-center mt-2">The best viewing experience</h6>
                                    <p className="quality text-center mt-2">The best viewing experience.</p>
                                </div>
                            </SwiperSlide>


                        </Swiper>
                    </div>
                </section>

                {/* Router slider end */}
            </div>
        </>
    )
}

export default RizwanDigital;