import React from "react";
import Help from "./Help/Help.js";


const HelpDesk = () => {   
    return (
      <>
      <Help/>
      </>
    );
};
export default HelpDesk;