import React  from 'react-router-dom';
import './App.css'
import { Route, Routes } from "react-router-dom";
import Header from './components/Header/Header';
import HomePage from "./components/HomePage/HomePage"
import BookNewConnection from './components/BookNewConnection/BookNewConnection';
import Plans from './components/Plans/Plans';
import OnlineRegistration from './components/OnlineRegistration/OnlineRegistartion.js'
import HomePlan from './components/HomePlan/HomePlan'
import SubScriberCorner from './components/SubscriberCorner/SubScriberCorner';
import TrackComplaint from './components/TrackComplaint/TrackComplaint.js';
import FileComplaint from './components/FileComplaint/FileComplaint.js'
import HelpDeskt from './components/HelpDesk/HelpDesk';
import Footer from './components/Footer/Footer';
import Upgrade from './components/Upgrade/Upgrade';





const App = () => {

  return (
    <>  
    <Header/>
    <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/book" element={<BookNewConnection/>} />
        <Route path="/plan" element={<Plans/>} />  
        <Route path="/homePlan" element={<HomePlan/>} />   
        <Route path="/registration" element={<OnlineRegistration/>} />    
        <Route path="/subscribe" element={<SubScriberCorner/>} />  
        <Route path="/complaint" element={<TrackComplaint/>} />
        <Route path="/filecomplaint" element={<FileComplaint/>} />
        <Route path="/help" element={<HelpDeskt/>} />
        <Route path="/upgrade" element={<Upgrade/>} />
      </Routes>
      <Footer/>
    </>
  )
}

export default App