import React from "react";
import SubScriberForm from '../SubscriberCorner/SubScriberForm/SubScriberForm.js'


const SubScriberCorner = () => {   
    return (
      <>
      <SubScriberForm/>

      </>
    );
};
export default SubScriberCorner;