import React from "react";
import '../Help/Help.css'



const Help = () => {
  return (
    <>
      <section className="main">
        <div className="container-fluid p-0">
          <div className="registration-form">
            <div className="container">
              <div className="text-container">
                <div className="row text-start p-4">
                  <div className="col-xl-11 col-md-11 col-11">
                    <p>
                      <a href="" className=" home-link">
                        Home
                      </a>
                      <span> &gt; </span> <span>Help & Support</span>
                    </p>
                  </div>
                </div>
                <div className="heading p-4 ">
                  <h3> Help Desk</h3>
                  <div className="d-flex">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                      <label class="form-check-label" for="exampleRadios1">
                        Carporate
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />
                        <label class="form-check-label" for="exampleRadios1">
                          Subscriber
                        </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="complete-form">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="border-one">
                  <div></div>
                </div>
                <div className="main-form">
                  <div className="form-section">
                    <form className="overlay-form">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <div className="mb-3 mt-5 ">
                              <input
                                type="text"
                                className="form-control"
                                id="phoneInput"
                                placeholder="Name"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="number"
                                className="form-control"
                                id="phoneInput"
                                placeholder="Mobile No"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control"
                                id="phoneInput"
                                placeholder="Subject"
                              />
                            </div>
                            <div className="mb-3">
                              <input
                                type="email"
                                className="form-control"
                                id="phoneInput"
                                placeholder="Email"
                              />
                            </div>
                            <form className="city-form mb-3" action="">
                              <select class="form-select" aria-label="Default select example">
                                <option selected>Type of Query </option>
                                <option value="2">1 </option>
                                <option value="2">2 </option>
                                <option value="3">3 </option>
                              </select>
                            </form>
                            <div className="mb-3">
                              <input
                                type="text"
                                className="form-control remarks"
                                id="phoneInput"
                                placeholder="Description"
                              />
                            </div>
                            <div className="custom-button  mb-5 ">
                              <button type="submit" className="btn">
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <img src={process.env.PUBLIC_URL + "assets/Images/Subscribe_Corner/girl_user.png"} className="user-img" />
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};
export default Help;
