import React from "react";
import PlansBanner from './PlansBanner/PlansBanner.js';
import TermsCondition from './TermsCondition/TermsCondition.js'
import WhyUcn from './WhyUcn/WhyUcn.js'

const Plans = () => {   
    return (
      <>
      <PlansBanner/>
      <TermsCondition/>
      <WhyUcn/>
      </>
    );
};
export default Plans;