import React from "react";
import UpgradeHd from "./UpgradeHd/UpgradeHd.js";


const Upgrade = () => {   
    return (
      <>
      <UpgradeHd/>
      </>
    );
};
export default Upgrade;