import React from "react";
import "../../Plans/PlansBanner/PlansBanner.css";
import { Link } from "react-router-dom"
const HomeBanner = () => {

  return (
    <>
      <div className="main">
        {/* plans package banner section start */}
        <section className="Banner">
          <div className="container">
            <div className="banner-text row align-items-center">
              <div className="col-xl-6 col-md-6 col-12">
                <div className="text-holder">
                  <h1>Plans for Your City</h1>
                </div>
                <form className="city-form" action="">
                  <label>Select Your City</label>
                  <select class="form-select" aria-label="Default select example">
                    <option selected>Select Your City</option>
                    <option value="1"> <Link to="/homeplan">Pune</Link></option>
                    <option value="2">Mumbai </option>
                    <option value="3">Kolhapur</option>
                  </select>
                </form>
              </div>
            </div>
          </div>
        </section>
        {/* plans package banner section end */}
      </div>
    </>
  );
};
export default HomeBanner;