import React from "react";
import "../Servicess/Servicess.css";
import { Link } from "react-router-dom";

const Servicess = () => {
  return (
    <>
      <section className="services-section ">
        <div className="container">



          <div className="bg-circle-div">
            <img className="bg-img" src={process.env.PUBLIC_URL + "assets/Images/OurServices/Group47.png"} />
          </div>

          <div className="service-text">
            <div className="d-flex align-items-center">
              <div className="icon">
                <img src={process.env.PUBLIC_URL + "assets/Images/OurServices/icon.png"} className="img-fluid"
                  alt=""
                />
              </div>
              <div className=" "><h2 className="heading p-2">Our Services</h2></div>
            </div>
            <div className="text-content px-4 align-items-start col-md-11">
              A world-class service with more channels, interactive features &
              value added services <b> at reasonable prices</b>
            </div>
            <div className="image-section py-5">
              <div className="row">
                <div className="col-lg-3 col-xl-3 col-md-6  mb-3">
                  <div className="images">
                    <div className="card">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/OurServices/Service-one.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="card-body">
                        <h2 className="card-title">Digital</h2>
                        <p className="card-text mb-4">
                          Cutting edge digital cable service, High quality picture
                          and stereophonic sound Set Top Box.
                        </p>
                        <Link href="#" className="btn btn-primary go_btn mt-1">
                          KNOW MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" col-lg-3 col-xl-3 col-md-6  mb-3">
                  <div className="images">
                    <div className="card">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/OurServices/Service-two.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="card-body">
                        <h2 className="card-title">HD</h2>
                        <p className="card-text">
                          Premium high definition cable service with Full HD video
                          and sound.
                        </p>
                        <Link href="#" className="btn btn-primary go_btn">
                          KNOW MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" col-lg-3 col-xl-3 col-md-6  mb-3 ">
                  <div className="images">
                    <div className="card">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/OurServices/service-three.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="card-body">
                        <h2 className="card-title">Broadband</h2>
                        <p className="card-text">
                          Amazing speed with affordable price.
                        </p>
                        <Link href="#" className="btn btn-primary go_btn">
                          KNOW MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-xl-3 col-md-6  mb-3 ">
                  <div className="images">
                    <div className="card">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "assets/Images/OurServices/service-four.png"
                        }
                        className="img-fluid"
                        alt="..."
                      />
                      <div className="card-body">
                        <h2 className="card-title">Local channels</h2>
                        <p className="card-text">
                          24 hour local channel Movies, Music, News, Rail & Air
                          Schedule..
                        </p>
                        <Link href="#" className="btn btn-primary go_btn">
                          KNOW MORE
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Servicess;
